import React, { useEffect } from "react";

function App() {
  useEffect(() => {
    const totalElement = document.getElementById('total3'); // Get the element with id 'total3'

    // Function to update totalValue
    const updateTotalValue = () => {
      const totalText = totalElement.textContent; // Fetch the total text content from the element
      const totalValue = parseFloat(totalText.replace(/[^\d.]/g, '')); // Extract the numerical value and convert it to a number

      // Display alert with the updated total value
      // alert("Updated Total Value: " + totalValue);

      // Render PayPal buttons with the updated totalValue
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  currency_code: 'USD',
                  value: localStorage.getItem('total'), // Use the parsed total value here
                },
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture().then((details) => {
            document.getElementById("main2").style.display = "none";
            document.getElementById("main3").style.display = "flex";
            setTimeout(function() {
                document.getElementById("main3").style.display = "none";
                document.getElementById("main4").style.display = "flex";
            }, 7000);
          });
        },
      }).render("#paypal-button");
    };

    // Initial update of totalValue
    updateTotalValue();

    // Create a MutationObserver to observe changes in the content of totalElement
    const observer = new MutationObserver(updateTotalValue);
    observer.observe(totalElement, { characterData: true, subtree: true });

    // Clean up function to disconnect the observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return <div id="paypal-button"></div>;
}


export default App;
